import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import compose from 'recompose/compose'
import {withTranslate} from 'react-redux-multilingual'
import {withStyles} from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import BookingInfoBasic from './BookingInfoBasic'
import BookingInfoQuestion from './BookingInfoQuestion'
import BookingInfoPhoto from './BookingInfoPhoto'
import Genders from '../modules/genders'
import Country from '../modules/countries'
import Question from '../modules/questions'
import Reservation from '../modules/reservation'
import TermsAndConditions from './TermsAndConditions'
import BookingInfoDocumentPhoto from './BookingInfoDocumentPhoto'
import BookingInfoPayment from './BookingInfoPayment'
import * as CreditCardValidator from 'card-validator'

import Loading from './Loading'
import Success from './Success'
import Error from './Error'
import ErrorMessage from './ErrorMessage'
import {isPaymentEnabled} from '../helpers/payment';
import { getIssuer } from '../helpers/credit_card'

const Isemail = require('isemail')

class BookingInfoRoomStep extends React.Component {
  state = {
    checkin_fields_behaviour: [],
    checkin_additional_field: [],
    loading: true,
    activeStep: 0,
    activeScreen: '',
    bookingInfo: {
      id: -1,
      check_in_date: null,
      check_out_date: null,
      name: '',
      surname: '',
      email: '',
      country_id: -1,
      total: 0,
      taxes: 0,
      accomodation_discount: 0,
      discount: 0,
      deposit: 0,
      coupon_discount_amount: null,
      rooms: [],
      edp: '',
      has_debit: false,
      debit: 0
    },
    checkin: {
      roomId: this.props.roomId,
      name: '',
      surname: '',
      gender: '-1',
      birth_date: null,
      email: '',
      document: '',
      tax_id: '',
      phone: '',
      country: '-1',
      city: '-1',
      state: '-1',
      q1_origin: '',
      q2_destination: '',
      q3_trip_motivation: '-1',
      q4_property_choose_motivation: '-1',
      q5_information: false,
      photo: null,
      document_photo: null,
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      postal_code: '',
      cc_number: '',
      cc_name: '',
      cc_valid_thru: '',
      cc_code: '',
      cc_issuer: '',
      cc_payment_amount: 0,
      additionalField: null
    },
    propertyInfo: {
      property_name: '',
      checkin_terms: ''
    },
    validEmailBasicInfo: false,
    documentValidationBasicInfo: false,
    validDateBirthBasicInfo: false,
    valid: true,
    isValid: true,
    creditCardValidationStatus: {
      number: true,
      name: true,
      expiration: true,
      cvv: true,
      amount: true
    },
    genders: [],
    countries: [],
    stateList: [],
    cityList: [],
    questions1: [],
    questions2: [],
    terms_accepted: false,
    open_terms: false,
    save_success: false,
    save_message: '',
    dateIn: new Date(),
    dateOut: new Date(),
    selfImageUpload: false,
    documentImageUpload: false,
    error_message: null
  }

  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.saveForm = this.saveForm.bind(this)
    this.resetSteps = this.resetSteps.bind(this)
  }

  async componentDidMount() {
    const defaultLocale = this.props.locale,
      genders = await Genders.getGenders(defaultLocale)

    if (genders) {
      const list = []
      genders.result.genders.forEach((items) => {
        list.push(items)
      })
      this.setState({ genders: list })
    }

    const countries = await Country.getCountries(defaultLocale)
    if (countries) {
      const listC = []
      countries.result.countries.forEach((items) => {
        listC.push(items)
      })
      this.setState({ countries: listC })
    }

    const questions = await Question.getQuestions(defaultLocale)

    if (questions) {
      const listQ1 = [],
        listQ2 = []

      questions.result.questions.forEach((items) => {
        if (items.hasOwnProperty('question')) {
          if (items.question === 'trip_motivation') {
            items.options.forEach((option) => {
              listQ1.push(option)
            })
          }

          if (items.question === 'property_choose_motivation') {
            items.options.forEach((option) => {
              listQ2.push(option)
            })
          }
        }
      })

      this.setState({ questions1: listQ1 })
      this.setState({ questions2: listQ2 })
    }

    const checkin = await Reservation.getCheckin(defaultLocale)

    if (checkin) {
      if (this.props.currentGuest > 1) {
        checkin.result.bookingInfo.name = ''
        checkin.result.bookingInfo.surname = ''
        checkin.result.bookingInfo.birth_date = null
        checkin.result.bookingInfo.email = ''
      }

      checkin.result.bookingInfo.roomId = this.props.roomId
      checkin.result.bookingInfo.phone = ''
      checkin.result.bookingInfo.gender = '-1'
      checkin.result.bookingInfo.country = '-1'
      checkin.result.bookingInfo.state = '-1'
      checkin.result.bookingInfo.city = '-1'
      checkin.result.bookingInfo.address = ''
      checkin.result.bookingInfo.number = ''
      checkin.result.bookingInfo.complement = ''
      checkin.result.bookingInfo.neighborhood = ''
      checkin.result.bookingInfo.postal_code = ''
      checkin.result.bookingInfo.q1_origin = ''
      checkin.result.bookingInfo.q2_destination = ''
      checkin.result.bookingInfo.q3_trip_motivation = '-1'
      checkin.result.bookingInfo.q4_property_choose_motivation = '-1'
      checkin.result.bookingInfo.q5_information = ''

      const dateIn = new Date(
        Date.UTC(
          +checkin.result.bookingInfo.check_in_date.split('-')[0],
          +checkin.result.bookingInfo.check_in_date.split('-')[1] - 1,
          parseInt(checkin.result.bookingInfo.check_in_date.split('-')[2], 10) +
          1,
          0,
          0,
          0
        )
      )

      const dateOut = new Date(
        Date.UTC(
          +checkin.result.bookingInfo.check_out_date.split('-')[0],
          +checkin.result.bookingInfo.check_out_date.split('-')[1] - 1,
          parseInt(
            checkin.result.bookingInfo.check_out_date.split('-')[2],
            10
          ) + 1,
          0,
          0,
          0
        )
      )

      let fields = []
      checkin.result.propertyInfo.checkin_fields_behaviour.forEach((e) => {
        e['visible'] = (parseInt(e.visible) === 1) ? true : false
        e['required'] = (parseInt(e.required) === 1) ? true : false
        fields.push(e)
      })

      // console.log("fields", fields);

      this.setState({
        bookingInfo: checkin.result.bookingInfo,
        checkin: checkin.result.bookingInfo,
        propertyInfo: checkin.result.propertyInfo,
        checkin_fields_behaviour: fields,
        checkin_additional_field: checkin.result.propertyInfo.checkin_additional_field,
        dateIn: dateIn,
        dateOut: dateOut
      })
    }

    this.setState({ loading: false })
  }

  render() {
    const { classes, theme, translate } = this.props
    const { activeStep } = this.state
    const { has_debit } = this.state.bookingInfo
    const takePayment = isPaymentEnabled() && this.state.propertyInfo.checkin_online_payment === '1'
    const maxSteps = has_debit && takePayment ? 6 : 5
    const saveStep = has_debit && takePayment ? activeStep === 4 : activeStep === 3

    return (
      <div className={classes.root}>
        <TermsAndConditions
          open={this.state.open_terms}
          content={this.state.propertyInfo.checkin_terms}
          handleClose={this.closeTerms}
        />

        {/* Checkin Header ( Booking main info ) */}
        <Paper square elevation={0} className={classes.header}>
          <div className={classes.div} style={{ display: this.state.loading ? 'none' : 'flex' }}>
            <Typography className={classes.title} component="p">
              {translate('reservationLabel')}
              <br /> {this.state.bookingInfo.id}
            </Typography>
            <Typography className={classes.title} component="p">
              Check in
              <br />{' '}
              {new Intl.DateTimeFormat(this.props.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }).format(this.state.dateIn)}{' '}
            </Typography>
            <Typography className={classes.title} component="p">
              Check out
              <br />{' '}
              {new Intl.DateTimeFormat(this.props.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }).format(this.state.dateOut)}{' '}
            </Typography>
          </div>
        </Paper>

        {/* Main Page Content */}
        {this.state.loading ? (
          <Loading visible={this.state.loading} />
        ) : (
          <>
            {this.state.error_message!==null && <ErrorMessage message={this.state.error_message} />}
            {this.getStepContent(activeStep)}
          </>
        )}

        {/* Page Steper ( Footer with Back and Next) */}
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          backButton={
            <Button
              size="small"
              className={classes.buttonText}
              onClick={this.handleBack}
              disabled={
                this.state.loading || activeStep === 0 || activeStep === maxSteps - 1
              }>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              {translate('previousLabel')}
            </Button>
          }
          nextButton={
            <Button
              size="small"
              className={classes.buttonText}
              onClick={this.handleNext}
              disabled={this.state.loading || activeStep === maxSteps - 1}
            >
              {saveStep ? translate('saveLabel') : translate('nextLabel')}
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
        />
      </div>
    )
  }

  handleChangeTF = (name) => (event) => {
    if (name === 'email') {
      let validEmailBasicInfo = !Isemail.validate(event.target.value)
      this.setState({
        validEmailBasicInfo
      })
    }
    if (name === 'document') {
      let documentValidationBasicInfo = event.target.value !== '' ? false : true
      this.setState({
        documentValidationBasicInfo
      })
    }
    if (name === 'birth_date') {
      let validDateBirthBasicInfo = event.target.value !== '' ? false : true
      this.setState({
        validDateBirthBasicInfo
      })
    }

    this.setState({
      checkin: {
        ...this.state.checkin,
        [name]: event.target.value
      }
    })
  }

  handlePaymentChange = (name, value) => {
    this.setState({
      checkin: {
        ...this.state.checkin,
        [name]: value
      }
    })
  }

  handleChange = (event) => {
    const name = event.target.name
    this.setState((prevState) => ({
      checkin: {
        ...prevState.checkin,
        [name]: event.target.value
      }
    }))
  }

  handleNext = () => {
    if (this.validate()) {
      this.setState((prevState) => ({
        valid: true,
        activeStep: prevState.activeStep + 1
      }))
    } else {
      this.setState((prevState) => ({
        valid: false,
        activeStep: prevState.activeStep
      }))
    }
  }

  handleSkip = () => {
    this.setState((prevState) => ({
      valid: true,
      activeStep: prevState.activeStep + 1,
      checkin: {
        ...prevState.checkin,
        cc_number: '',
        cc_name: '',
        cc_valid_thru: '',
        cc_code: '',
        cc_issuer: ''
      }
    }))
  }

  handleBack = () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }))
  }

  handleChangeSw = (name) => (event) => {
    this.setState({
      checkin: {
        ...this.state.checkin,
        [name]: event.target.checked
      }
    })
  }

  seeTerms = () => (event) => {
    this.setState({
      terms_accepted: event.target.checked,
      open_terms: event.target.checked
    })
  }

  closeTerms = () => {
    this.setState({ open_terms: false })
  }

  resetSteps = () => {
    let checkin = {}

    this.props.updateCurrentGuest(this.props.currentGuest + 1)

    checkin.roomId = this.props.roomId
    checkin.gender = '-1'
    checkin.country = '-1'
    checkin.state = '-1'
    checkin.city = '-1'
    checkin.phone = ''
    checkin.q1_origin = ''
    checkin.q2_destination = ''
    checkin.q3_trip_motivation = ''
    checkin.q4_property_choose_motivation = ''
    checkin.q5_information = ''
    checkin.name = ''
    checkin.surname = ''
    checkin.birth_date = null
    checkin.email = ''
    checkin.document = ''
    checkin.tax_id = ''
    checkin.photo = null
    checkin.document_photo = null
    checkin.address = ''
    checkin.number = ''
    checkin.complement = ''
    checkin.neighborhood = ''
    checkin.city = ''
    checkin.state = ''
    checkin.postal_code = ''
    checkin.document_photo = null
    checkin.cc_number = ''
    checkin.cc_name = ''
    checkin.cc_valid_thru = ''
    checkin.cc_code = ''
    checkin.cc_issuer = ''
    checkin.cc_payment_amount = this.state.checkin.cc_payment_amount

    this.setState({
      terms_accepted: false,
      save_success: false,
      save_message: '',
      activeStep: 0,
      checkin: checkin
    })
  }

  checkSelfImageUpload = () => {
    this.setState({
      selfImageUpload: true
    })
  }

  checkDocumentImageUpload = () => {
    this.setState({
      documentImageUpload: true
    })
  }


  getStepContent(step) {
    const takePayment =
      isPaymentEnabled() &&
      this.state.bookingInfo.has_debit &&
      this.state.propertyInfo.checkin_online_payment === '1'
    const screen = this.findScreenSlug(step, takePayment)

    switch (screen) {
      case 'guest-info': {
        return (
          <BookingInfoBasic
            valid={this.state.valid}
            checkin={this.state.checkin}
            bookingInfo={this.state.bookingInfo}
            genders={this.state.genders}
            countries={this.state.countries}
            stateList={this.state.stateList}
            cityList={this.state.cityList}
            handleChangeTF={this.handleChangeTF}
            handleChange={this.handleChange}
            checkinFieldsBehaviour={this.state.checkin_fields_behaviour}
            validEmail={this.state.validEmailBasicInfo}
            validDocument={this.state.documentValidationBasicInfo}
            validDateBirth={this.state.validDateBirthBasicInfo}
          />
        )
      }

      case 'guest-photo': {
        return (
          <BookingInfoPhoto
            bookingInfo={this.state.bookingInfo}
            checkin={this.state.checkin}
            propertyName={this.state.propertyInfo.property_name}
            checkSelfImageUpload= {()=> this.checkSelfImageUpload()}
          />
        )
      }

      case 'guest-document-photo': {
        return (
          <BookingInfoDocumentPhoto
            bookingInfo={this.state.bookingInfo}
            checkin={this.state.checkin}
            propertyName={this.state.propertyInfo.property_name}
            checkDocumentImageUpload= {()=> this.checkDocumentImageUpload()}
          />
        )
      }

      case 'guest-payment': {
        return (
          <BookingInfoPayment
            checkin={this.state.checkin}
            bookingInfo={this.state.bookingInfo}
            validationStatus={this.state.creditCardValidationStatus}
            handlePaymentChange={this.handlePaymentChange}
            handleSkip={this.handleSkip}
            propertyName={this.state.propertyInfo.property_name}
          />
        )
      }

      case 'guest-questions': {
        return (
          <BookingInfoQuestion
            valid={this.state.valid}
            terms_accepted={this.state.terms_accepted}
            checkin={this.state.checkin}
            questions1={this.state.questions1}
            questions2={this.state.questions2}
            handleChangeTF={this.handleChangeTF}
            handleChange={this.handleChange}
            checkinAdditionalField={this.state.checkin_additional_field}
            handleChangeSw={this.handleChangeSw}
            seeTerms={this.seeTerms}
            saveForm={this.saveForm}
            checkinFieldsBehaviour={this.state.checkin_fields_behaviour}
            propertyName={this.state.propertyInfo.property_name}
          />
        )
      }

      case 'success': {
        return this.state.save_success ? (
          <Success
            resetSteps={this.resetSteps}
            edp={this.state.bookingInfo.edp}
            continue={this.props.currentGuest < this.props.totalGuests}
            propertyName={this.state.propertyInfo.property_name}
          />
        ) : (
          <Error message={this.state.save_message} />
        )
      }

      default: {
        return <div></div>
      }
    }
  }

  validate() {
    const { translate } = this.props
    const { checkin_fields_behaviour } = this.state;
    const takePayment =
      isPaymentEnabled() &&
      this.state.bookingInfo.has_debit &&
      this.state.propertyInfo.checkin_online_payment === '1'

    const screen = this.findScreenSlug(this.state.activeStep, takePayment)

    switch (screen) {
      case 'guest-info': {
        const checkin_fields_behaviour = this.state.checkin_fields_behaviour

        if (
          checkin_fields_behaviour.filter((e) => e.field === 'document')[0].required &&
          (this.state.checkin.document === '' || this.state.checkin.document === undefined)
        ) {
          this.setState({ documentValidationBasicInfo: true })
        }
        if (
          checkin_fields_behaviour.filter((e) => e.field === 'email')[0].required &&
          this.state.checkin.email === ''
        ) {
          this.setState({ validEmailBasicInfo: true })
        }

        if (
          checkin_fields_behaviour.filter((e) => e.field === 'date_of_birth')[0].required &&
          (this.state.checkin.birth_date === undefined ||
            this.state.checkin.birth_date === '')
        ) {
          this.setState({ validDateBirthBasicInfo: true })
        }

        return !(
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'name')[0].required && this.state.checkin.name === '') ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'surname')[0].required && this.state.checkin.surname === '') ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'email')[0].required && this.state.checkin.email === '') ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'phone')[0].required && this.state.checkin.phone === '') ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'document')[0].required && (this.state.checkin.document === '' || this.state.checkin.document === undefined)) ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'tax_id')[0].required && (this.state.checkin.tax_id === '' || this.state.checkin.tax_id === undefined)) ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'date_of_birth')[0].required && (this.state.checkin.birth_date === undefined || this.state.checkin.birth_date === '')) ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'gender')[0].required && this.state.checkin.gender < 0) ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'address')[0].required && this.state.checkin.address === '') ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'number')[0].required && this.state.checkin.number === '') ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'complement')[0].required && this.state.checkin.complement === '') ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'neighborhood')[0].required && this.state.checkin.neighborhood === '') ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'city')[0].required && this.state.checkin.city === '') ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'state')[0].required && this.state.checkin.state === '') ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'post_code')[0].required && this.state.checkin.postal_code === '') ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'country')[0].required && this.state.checkin.country < 0) ||
          (checkin_fields_behaviour.filter((e) => e.hasOwnProperty('field') && e.field === 'email')[0].required && !Isemail.validate(this.state.checkin.email))
        )
      }

      case 'guest-photo': {
        const { selfImageUpload } = this.state;
        this.setState({...this.state, error_message: null});
        if (checkin_fields_behaviour.filter((e) => e.field === 'upload_photo')[0].required && selfImageUpload === false) {
          this.setState({...this.state, error_message: translate('uploadPhotoRequired')});
          return false;
        } else {
          return true
        }
      }

      case 'guest-document-photo': {
        const { documentImageUpload } = this.state;
        this.setState({...this.state, error_message: null});
        if (checkin_fields_behaviour.filter((e) => e.field === 'upload_document')[0].required && documentImageUpload === false) {
          this.setState({...this.state, error_message: translate('uploadDocumentRequired')});
          return false;
        } else {
          return true
        }
      }

      case 'guest-payment': {
        const canValidate = this.state.checkin.cc_number.toString() !== ''

        const cc_issuer = getIssuer(this.state.checkin.cc_number);
        const ccIssuerAcceptableCvvLength = (cc_issuer === 'amex') ? [3,4] : [3];

        if (canValidate) {
          const numberValidation = CreditCardValidator.number(
            this.state.checkin.cc_number
          ),
            validThruValidation = CreditCardValidator.expirationDate(
              this.state.checkin.cc_valid_thru
            ),
            codeValidation = CreditCardValidator.cvv(this.state.checkin.cc_code, ccIssuerAcceptableCvvLength)

          if (
            !numberValidation.isValid ||
            this.state.checkin.cc_name === '' ||
            !validThruValidation.isValid ||
            !codeValidation.isValid ||
            parseFloat(this.state.checkin.cc_payment_amount) >
            parseFloat(this.state.bookingInfo.debit)
          ) {
            return false
          }
        }

        return true
      }

      case 'guest-questions': {
        if (
          (this.state.checkin_fields_behaviour.filter(
            (e) => e.field === 'q1_origin'
          )[0].required &&
            this.state.checkin.q1_origin === '') ||
          (this.state.checkin_fields_behaviour.filter(
            (e) => e.field === 'q2_destination'
          )[0].required &&
            this.state.checkin.q2_destination === '') ||
          (this.state.checkin_fields_behaviour.filter(
            (e) => e.field === 'q3_trip_motivation'
          )[0].required &&
            this.state.checkin.q3_trip_motivation === '') ||
          (this.state.checkin_fields_behaviour.filter(
            (e) => e.field === 'q4_property_choose_motivation'
          )[0].required &&
            this.state.checkin.q4_property_choose_motivation === '') ||
          !this.state.terms_accepted
        ) {
          return false
        } else {
          this.saveForm()
          return true
        }
      }

      default: {
        return true
      }
    }
  }

  saveForm = () => {
    this.setState({ loading: true })

    let checkin1 = this.state.checkin,
      dateString = checkin1.birth_date

    if (dateString !== undefined && dateString !== null) {
      let dateStringSplit = dateString.split('/'),
        dateformat = this.props.translate('dateformat')

      checkin1.birth_date =
        dateformat === 'dd/MM/yyyy'
          ? new Date(dateStringSplit[2], dateStringSplit[1] - 1, dateStringSplit[0])
          : new Date(dateStringSplit[2], dateStringSplit[0] - 1, dateStringSplit[1])
    } else {
      checkin1.birth_date = '0000-00-00'
    }

    Reservation.saveCheckin(checkin1).then((response) => {
      this.setState({ save_success: response.status })

      if (response.result.hasOwnProperty('error_message')) {
        this.setState({ save_message: response.result.error_message })
      }

      this.setState({ loading: false })
    })
  }

  findScreenSlug = (step, hasPayment) => {
    hasPayment = hasPayment || false

    if (hasPayment) {
      switch (+step) {
        case 0: {
          return 'guest-info'
        }
        case 1: {
          return 'guest-photo'
        }
        case 2: {
          return 'guest-document-photo'
        }
        case 3: {
          return 'guest-payment'
        }
        case 4: {
          return 'guest-questions'
        }
        case 5: {
          return 'success'
        }
        default: {
          return ''
        }
      }
    } else {
      switch (+step) {
        case 0: {
          return 'guest-info'
        }
        case 1: {
          return 'guest-photo'
        }
        case 2: {
          return 'guest-document-photo'
        }
        case 3: {
          return 'guest-questions'
        }
        case 4: {
          return 'success'
        }
        default: {
          return ''
        }
      }
    }
  }
}

const styles = (theme) => ({
  root: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(550 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  header: {
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: '#303e46',
    width: '100%',
    overflow: 'hidden'
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  title: {
    color: '#fff',
    fontSize: '11px',
    width: '33.3%',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowWrap: 'break-word',
    padding: 2
  },
  buttonText: {
    fontSize: '11px'
  },
  div: {
    display: 'flex',
    textAlign: 'center'
  },
  mobileStepper: {
    marginTop: 20
  },
  errorText: {
    color: '#f44336'
  }
})

BookingInfoRoomStep.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  translations: PropTypes.object
}

function mapPropsToState(state) {
  const { Intl } = state

  return { ...Intl, key: Intl.locale }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapPropsToState)
)(withTranslate(BookingInfoRoomStep))
