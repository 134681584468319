export function getWhatsappLink(url, title, isMobile) {
  return (
    'https://' +
    (isMobile ? 'api' : 'web') +
    '.whatsapp.com/send?text=' +
    title +
    ' ' +
    url
  )
}

export function getEmailLink(subject, body) {
  return 'mailto:?subject=' + subject + '&amp;body=' + body
}
