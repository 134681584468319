import React from 'react'
import 'date-fns'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Cards from 'react-credit-cards'
import InputAdornment from '@material-ui/core/InputAdornment'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import LockIcon from '@material-ui/icons/Lock'
import TextField from '@material-ui/core/TextField'
import CurrencyFormat from 'react-currency-format'
import {
  currencyToFloat,
  floatToCurrency,
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  getIssuer
} from '../helpers/credit_card'
import * as CreditCardValidator from 'card-validator'

class BookingInfoPayment extends React.Component {
  state = {
    focused: '',
    ccIssuer: '',
    ccIssuerAcceptableCvvLength: [3],
    formData: null
  }

  constructor(props) {
    super(props)

    this.paymentField = this.paymentField.bind(this)
  }

  //region Custom Fields
  paymentField(props) {
    const { inputRef, ...other } = props

    return (
      <CurrencyFormat
        {...other}
        prefix={'R$ '}
        suffix={''}
        thousandSeparator={'.'}
        decimalSeparator={','}
        allowNegative={false}
        type={'tel'}
        decimalScale={2}
        fixedDecimalScale={true}
        onValueChange={this.handlePaymentAmountChange}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null)
        }}
      />
    )
  }
  //endregion

  //region Events
  updateRef(name, ref) {
    this[name] = ref
  }

  handleInputFocus = ({ target }) => {
    let name = ''

    switch (target.name) {
      case 'cc_number': {
        name = 'number'
        break
      }
      case 'cc_name': {
        name = 'name'
        break
      }
      case 'cc_valid_thru': {
        name = 'expiry'
        break
      }
      case 'cc_code': {
        name = 'cvc'
        break
      }
      default: {
        name = ''
        break
      }
    }

    this.setState({ focused: name })
  }

  handleInputChange = ({ target }) => {
    if (target.name === 'cc_number') {

      const issuer = getIssuer(target.value);
      this.setState({ 
        ccIssuer: issuer, 
        ccIssuerAcceptableCvvLength: (issuer === 'amex') ? [3,4] : [3] 
      });
      target.value = formatCreditCardNumber(target.value);

    } else if (target.name === 'cc_valid_thru') {
      target.value = formatExpirationDate(target.value)
    } else if (target.name === 'cc_code') {
      target.value = formatCVC(target.value)
    } else if (target.name === 'cc_payment_amount') {
      target.value = currencyToFloat(target.value)

      target.name = 'cc_payment_amount'
    }

    this.props.handlePaymentChange(target.name, target.value)
  }

  handlePaymentAmountChange = (values) => {
    this.props.handlePaymentChange('cc_payment_amount', values.floatValue)
  }
  //endregion

  render() {
    const { classes, translate, checkin } = this.props
    const {
      checkin: { cc_number, cc_name, cc_valid_thru, cc_code, cc_payment_amount }
    } = this.props
    const { focused } = this.state

    let debit = parseFloat(checkin.debit)
      .toFixed(2)
      .toString()
      .replace('.', ','),
      cc_base_payment_amount = floatToCurrency(cc_payment_amount)

    const paymentAmountText = (
      <CurrencyFormat
        value={debit}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'R$ '}
      />
    )

    const locale = {
      number: translate('ccCardNumberLabel').toLowerCase(),
      name: translate('ccFullNameLabel').toLowerCase(),
      valid: translate('ccValidThruLabel').toLowerCase(),
      cvc: translate('ccCodeLabel').toLowerCase()
    }

    const placeholders = {
      number: translate('ccCardNumberLabel'),
      name: translate('ccYourName'),
      valid: translate('ccValidThruLabel'),
      cvc: translate('ccCodeLabel')
    }

    return (
      <React.Fragment>
        <Grid container className={classes.root}>
          <Grid item xs={12} className={classes.gridItemClasses}>
            <Typography className={classes.title} component="h2">
              {translate('paymentLabel')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItemClasses}>
            <Typography className={classes.text} component="p">
              {translate('debitLabel')}{' '}
              <span className={classes.payment}>{paymentAmountText}</span>
            </Typography>
          </Grid>
          <Grid className={classes.gridItemClasses} item xs={12}>
            <Cards
              number={cc_number}
              name={cc_name}
              expiry={cc_valid_thru}
              cvc={cc_code}
              focused={focused}
              locale={locale}
              placeholders={placeholders}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItemClasses}>
            <TextField
              id="cc_number"
              name="cc_number"
              label={translate('ccCardNumberLabel')}
              value={cc_number}
              error={!this.isValid('cc_number')}
              helperText={
                !this.isValid('cc_number')
                  ? translate('ccCardNumberInputHelperText')
                  : ''
              }
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              pattern="[\d| ]{16,22}"
              autoFocus
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon />
                  </InputAdornment>
                ),
                maxLength: 20
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItemClasses}>
            <TextField
              id="cc_name"
              name="cc_name"
              label={translate('ccFullNameLabel')}
              value={cc_name}
              error={!this.isValid('cc_name')}
              helperText={
                !this.isValid('cc_name')
                  ? translate('ccFullNameInputHelperText')
                  : ''
              }
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
                maxLength: 50,
                readOnly: !this.canValidate()
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.gridItemClasses}>
            <TextField
              id="cc_valid_thru"
              name="cc_valid_thru"
              label={translate('ccValidThruLabel')}
              value={cc_valid_thru}
              error={!this.isValid('cc_valid_thru')}
              helperText={
                !this.isValid('cc_valid_thru')
                  ? translate('ccValidThruInputHelperText')
                  : ''
              }
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
                maxLength: 50,
                readOnly: !this.canValidate()
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.gridItemClasses}>
            <TextField
              id="cc_code"
              name="cc_code"
              label={translate('ccCodeLabel')}
              value={cc_code}
              error={!this.isValid('cc_code')}
              helperText={
                !this.isValid('cc_code')
                  ? translate('ccCodeInputHelperText')
                  : ''
              }
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                maxLength: 5,
                readOnly: !this.canValidate()
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItemClasses}>
            <TextField
              id="cc_payment_amount"
              name="cc_payment_amount"
              label={translate('ccPaymentAmount')}
              value={cc_base_payment_amount}
              error={!this.isValid('cc_payment_amount')}
              helperText={
                !this.isValid('cc_payment_amount')
                  ? translate('paymentAmountInputHelperText')
                  : ''
              }
              onFocus={this.handleInputFocus}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MonetizationOnIcon />
                  </InputAdornment>
                ),
                inputComponent: this.paymentField,
                readOnly: !this.canValidate()
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.obs} component="small">
              {translate('paymentInfo')}{' '}
              <a href="." onClick={this.onSkipStep}>
                {translate('paymentInfoAction')}
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  onSkipStep = (event) => {
    event.preventDefault()
    this.props.handleSkip()
  }

  canValidate = () => {
    return this.props.checkin.cc_number !== ''
  }

  isValid = (field) => {
    const canValidate = this.canValidate(),
      globalValid = this.props.valid

    let valid = true

    if (canValidate) {
      switch (field) {
        case 'cc_number': {
          const numberValidation = CreditCardValidator.number(
            this.props.checkin.cc_number
          )
          valid = numberValidation.isValid
          break
        }

        case 'cc_name': {
          valid = this.props.checkin.cc_name !== ''
          break
        }

        case 'cc_valid_thru': {
          const validThruValidation = CreditCardValidator.expirationDate(
            this.props.checkin.cc_valid_thru
          )
          valid = validThruValidation.isValid
          break
        }

        case 'cc_code': {
          const codeValidation = CreditCardValidator.cvv(
            this.props.checkin.cc_code,
            this.state.ccIssuerAcceptableCvvLength
          )
          valid = codeValidation.isValid
          break
        }

        case 'cc_payment_amount': {
          valid =
            parseFloat(this.props.checkin.cc_payment_amount) <=
            parseFloat(this.props.bookingInfo.debit)
          break
        }

        default: {
          valid = true
          break
        }
      }
    }

    return !globalValid && valid
  }
}

const styles = (theme) => ({
  root: {
    padding: 20
  },
  gridItemClasses: {
    paddingTop: 15,
    paddingBottom: 15
  },
  title: {
    color: '#000',
    textAlign: 'center',
    fontSize: 24
  },
  text: {
    color: '#000',
    borderBottom: '1px solid #ccc',
    fontWeight: 'bold',
    textAlign: 'left'
  },
  payment: {
    fontSize: 18
  },
  obs: {
    fontSize: 11,
    marginTop: 10
  }
})

BookingInfoPayment.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect()
)(withTranslate(BookingInfoPayment))
