
export function isPaymentEnabled() {
    let enabled = true;
  
    if (process.env.hasOwnProperty('REACT_APP_PAYMENT_ENABLED')) {
      enabled = +process.env.PAYMENT_ENABLED === 1;
    }
  
    return enabled;
  }
  