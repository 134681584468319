import React from 'react'
import 'date-fns'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Places from '../modules/places'

class BookingInfoQuestion extends React.Component {
  state = {
    open: false,
    single: '',
    popper: '',
    single2: '',
    popper2: '',

    places1: [],
    places2: [],
    findPlaces1Loaded: true,
    findPlaces2Loaded: true
  }

  handleChangeFindPlace1 = async (event) => {
    const _value = event.target.value

    if (_value.length >= 3) {
      if (this.state.findPlaces1Loaded) {
        this.state.findPlaces1Loaded = false
        const response = await Places.findPlace(event.target.value)
        if (response.status === true) {
          this.setState({ places1: response.result.places })
        }
        this.state.findPlaces1Loaded = true
      }
    } else {
      this.setState({ places1: [] })
    }
  }

  handleChangeSelectPlace1 = async (place) => {
    this.setState({
      q1_origin: place
    })
    if (place !== null) {
      this.props.checkin.q1_origin = place.id
    }
  }

  handleChangeFindPlace2 = async (event) => {
    const _value = event.target.value
    if (_value.length >= 3) {
      if (this.state.findPlaces2Loaded) {
        this.state.findPlaces2Loaded = false
        const response = await Places.findPlace(event.target.value)
        if (response.status === true) {
          this.setState({ places2: response.result.places })
        }
        this.state.findPlaces2Loaded = true
      }
    } else {
      this.setState({ places2: [] })
    }
  }

  handleChangeSelectPlace2 = async (place) => {
    this.setState({
      q2_destination: place
    })

    if (place !== null) {
      this.props.checkin.q2_destination = place.id
    }
  }

  render() {
    const { classes, translate, checkinFieldsBehaviour: fields } = this.props

    return (
      <React.Fragment>
        <Grid container className={classes.root}>
          <Typography variant="h6" gutterBottom className={classes.heading}>
            {translate('moreAboutYouLabel')}
          </Typography>

          {fields.filter((e) => e.field === 'q1_origin')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <Autocomplete
                id="q1_origin"
                style={{ width: '100%' }}
                margin="normal"
                required={
                  this.props.checkinFieldsBehaviour.filter(
                    (e) => e.field === 'q1_origin'
                  )[0].required
                }
                label={translate('question1Label')}
                options={this.state.places1}
                onChange={(a, b) => this.handleChangeSelectPlace1(b)}
                getOptionSelected={(option, value) =>
                  option.place_name === value.name
                }
                getOptionLabel={(option) => option.place_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate('question1Label')}
                    onChange={this.handleChangeFindPlace1}
                    fullWidth
                  />
                )}
              />
              <FormHelperText id={classes.errorText}>
                {this.props.checkinFieldsBehaviour.filter(
                  (e) => e.field === 'q1_origin'
                )[0].required &&
                !this.props.valid &&
                this.props.checkin.q1_origin === ''
                  ? translate('questionHelperText')
                  : ''}
              </FormHelperText>
            </Grid>
          )}
          {fields.filter((e) => e.field === 'q2_destination')[0].visible && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={6}>
              <Autocomplete
                id="q2_destination"
                style={{ width: '100%' }}
                margin="normal"
                label={translate('question2Label')}
                required={
                  this.props.checkinFieldsBehaviour.filter(
                    (e) => e.field === 'q2_destination'
                  )[0].required
                }
                options={this.state.places2}
                onChange={(a, b) => this.handleChangeSelectPlace2(b)}
                getOptionSelected={(option, value) =>
                  option.place_name === value.name
                }
                getOptionLabel={(option) => option.place_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate('question2Label')}
                    onChange={this.handleChangeFindPlace2}
                    fullWidth
                  />
                )}
              />
              <FormHelperText id={classes.errorText}>
                {this.props.checkinFieldsBehaviour.filter(
                  (e) => e.field === 'q2_destination'
                )[0].required &&
                !this.props.valid &&
                this.props.checkin.q2_destination === ''
                  ? translate('questionHelperText')
                  : ''}
              </FormHelperText>
            </Grid>
          )}

          {fields.filter((e) => e.field === 'q3_trip_motivation')[0]
            .visible && (
            <Grid item className={classes.gridItemClasses} xs={12}>
              <Select
                value={this.props.checkin.q3_trip_motivation}
                onChange={this.props.handleChange}
                name="q3_trip_motivation"
                fullWidth
                input={<Input name="q3" id="q3-label-placeholder" />}
                displayEmpty
                required={
                  this.props.checkinFieldsBehaviour.filter(
                    (e) => e.field === 'q3_trip_motivation'
                  )[0].required
                }
                error={
                  this.props.checkinFieldsBehaviour.filter(
                    (e) => e.field === 'q3_trip_motivation'
                  )[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.q3_trip_motivation < 0
                }
                id={'q3-required'}
              >
                <MenuItem value="-1" disabled>
                  {translate('question3Label')}
                </MenuItem>
                {this.props.questions1.map((question, index) => (
                  <MenuItem value={question.id} key={index}>
                    {question.desc}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id={classes.errorText}>
                {this.props.checkinFieldsBehaviour.filter(
                  (e) => e.field === 'q3_trip_motivation'
                )[0].required &&
                !this.props.valid &&
                this.props.checkin.q3_trip_motivation < 0
                  ? translate('questionHelperText')
                  : ''}
              </FormHelperText>
            </Grid>
          )}

          {fields.filter((e) => e.field === 'q4_property_choose_motivation')[0]
            .visible && (
            <Grid item className={classes.gridItemClasses} xs={12}>
              <Select
                value={this.props.checkin.q4_property_choose_motivation}
                onChange={this.props.handleChange}
                name="q4_property_choose_motivation"
                fullWidth
                input={<Input name="q4" id="q4-label-placeholder" />}
                displayEmpty
                required={
                  fields.filter(
                    (e) => e.field === 'q4_property_choose_motivation'
                  )[0].required
                }
                error={
                  fields.filter(
                    (e) => e.field === 'q4_property_choose_motivation'
                  )[0].required &&
                  this.props.valid === false &&
                  this.props.checkin.q4_property_choose_motivation < 0
                }
                id="q4-required"
              >
                <MenuItem value="-1" disabled>
                  {translate('question4Label')}
                </MenuItem>
                {this.props.questions2.map((question, index) => (
                  <MenuItem value={question.id} key={index}>
                    {question.desc}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {fields.filter(
                  (e) => e.field === 'q4_property_choose_motivation'
                )[0].required &&
                !this.props.valid &&
                this.props.checkin.q4_property_choose_motivation < 0
                  ? translate('questionHelperText')
                  : ''}
              </FormHelperText>
            </Grid>
          )}

          {parseInt(this.props.checkinAdditionalField[0].active) === 1 && (
            <Grid item className={classes.gridItemClasses} xs={12} sm={12}>
              <Typography variant="h6" className={classes.heading}>
                <small>
                  {this.props.checkinAdditionalField[0].label === ''
                    ? translate(`additionalFieldLabel`)
                    : this.props.checkinAdditionalField[0].label}
                </small>
              </Typography>
              <TextField
                multiline
                minRows={8}
                id={'additional_info'}
                name={'additional_info'}
                value={this.props.checkin['additional_info'] || ''}
                onChange={this.props.handleChangeTF('additional_info')}
                className={classes.inputField}
                fullWidth
              />
            </Grid>
          )}

          <Grid item className={classes.gridItemClasses} xs={12}>
            <FormControlLabel
              onChange={this.props.seeTerms()}
              control={
                <Checkbox color="secondary" name="acceptTerms" value="yes" />
              }
              label={translate('acceptTermsLabel')}
            />
            <FormHelperText>
              {!this.props.valid && !this.props.terms_accepted
                ? translate('acceptTermsHelperText')
                : ''}
            </FormHelperText>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  DateFormatInput: {
    '& div div': {
      height: '31px'
    }
  },
  gridItemClasses: {
    paddingTop: 15,
    paddingBottom: 15
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#f2994a'
    }
  },
  heading: {
    color: theme.palette.text.secondary,
    width: '100%',
    fontSize: theme.typography.pxToRem(15),
    paddingTop: '24px'
  },
  inputField: {
    marginTop: 15
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  root: {
    padding: 20,
    [theme.breakpoints.down('md')]: {
      minWidth: '100%'
    }
  },
  button: {
    float: 'right'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1)
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  divider: {
    height: theme.spacing(2)
  },
  errorText: {
    color: '#f44336'
  }
})

BookingInfoQuestion.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect()
)(withTranslate(BookingInfoQuestion))
